
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$md-moola: (
    50 : #f1e8fe,
    100 : #dcc6fc,
    200 : #c4a1fa,
    300 : #ac7bf7,
    400 : #9b5ef6,
    500 : #8942f4,
    600 : #813cf3,
    700 : #7633f1,
    800 : #6c2bef,
    900 : #591dec,
    A100 : #ffffff,
    A200 : #f2eeff,
    A400 : #cdbbff,
    A700 : #baa1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$moola-web-marketplace-primary: mat.define-palette($md-moola, 500);
$moola-web-marketplace-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$moola-web-marketplace-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$moola-web-marketplace-theme: mat.define-light-theme((
  color: (
    primary: $moola-web-marketplace-primary,
    accent: $moola-web-marketplace-accent,
    warn: $moola-web-marketplace-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($moola-web-marketplace-theme);

/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
  font-family: 'Circular Std';
  src: url('../src/assets/fonts/CircularStd/CircularStd-Medium.ttf'),
  url('../src/assets/fonts/CircularStd/CircularStd-Black.ttf'),
  url('../src/assets/fonts/CircularStd/CircularStd-Bold.ttf'),
  url("../src/assets/fonts/CircularStd/CircularStd-BlackItalic.ttf")
  /* Add additional src lines if you have multiple font file formats */
  /* Specify other font properties like font-weight and font-style if needed */
}
